.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#language-selector {
  display: flex;
  align-items: center;
}

.language-icon, .arrow-icon {
  width: 16px;
  height: 16px;
  background:url('https://cdnjs.cloudflare.com/ajax/libs/twemoji/12.1.5/72x72/1f1f7-1f1fa.png') no-repeat center center; 
  background-size: cover;
}

.arrow-icon {
  margin-left: 8px;
}
