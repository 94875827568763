.hero-input {
  color: #3c3c3c;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none !important;
}

.hero-input::placeholder {
  color: #3c3c3c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

input[type="checkbox"] {
  cursor: pointer;
}

.top-mini-banner {
  top: -50px;
  right: -50px;
}

.top-mini-text {
  font-size: 70px;
}

.bottom-mini-banner {
  bottom: 0px;
  right: 0px;
}

.center-information {
  display: none;
}

@media  screen and (max-width: 1440px) {
  .top-mini-banner {
    top: -80px;
    right: -80px;
  }
  
  .top-mini-text {
    font-size: 50px;
  }
  .bottom-mini-banner {
    bottom: 0px;
    right: -50px;
  }
  
  .right-information {
    display: none;
  }

  .center-information {
    display: block;
  }
}

@media screen and (max-width: 1230px) {
  .top-mini-banner {
    display: none;
  }
  .bottom-mini-banner {
   display: none;
  }
  
  .hero-content {
    max-width: 100%;
  }
}